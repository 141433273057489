// import React, { useState, useEffect } from "react";
// import {
//   Text,
//   Select,
//   Flex,
//   Paper,
//   Box,
//   ScrollArea,
//   Loader,
// } from "@mantine/core";
// import { IconChevronDown, IconSearch } from "@tabler/icons-react";
// import { useNavigate } from "react-router-dom";
// import { useAuth } from "../../context/AuthContext";
// import "./BookDevice.css";

// export default function BookDevice() {
//   const navigate = useNavigate();
//   const { user } = useAuth();
//   console.log(user);
//   const [devicesData, setDevicesData] = useState({ categories: {} });
//   const [selectedCategory, setSelectedCategory] = useState("");
//   const [selectedPlatform, setSelectedPlatform] = useState("");
//   const [loading, setLoading] = useState(true);
//   const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

//   useEffect(() => {
//     fetch(`${API_BASE_URL}`)
//       .then((response) => response.json())
//       .then((data) => {
//         setDevicesData({ categories: data });
//         const initialCategory = Object.keys(data)[0];
//         const initialPlatform = data[initialCategory][0].Platform;
//         setSelectedCategory(initialCategory);
//         setSelectedPlatform(initialPlatform);
//         setLoading(false);
//       })
//       .catch((error) => {
//         console.error("Error fetching devices data:", error);
//         setLoading(false);
//       });
//   }, [API_BASE_URL]);

//   useEffect(() => {
//     if (selectedCategory) {
//       setSelectedPlatform(devicesData.categories[selectedCategory][0].Platform);
//     }
//   }, [selectedCategory, devicesData]);

//   const handleDeviceSelect = (DeviceName, Platform) => {
//     navigate(
//       `/book-device/bookDeviceList/${selectedCategory}/${Platform}/${DeviceName}`
//     );
//   };

//   const platforms =
//     selectedCategory && devicesData.categories[selectedCategory]
//       ? devicesData.categories[selectedCategory].map(
//           (category) => category.Platform
//         )
//       : [];

//   return (
//     <div>
//       <Flex justify="space-between" align="center" px="md">
//         <Flex align="center" gap={"xl"}>
//           <Text c={"#393939BA"} fw={"bold"} size="20px">
//             Book Device
//           </Text>
//           <Select
//             placeholder="Select Category"
//             data={Object.keys(devicesData.categories)}
//             value={selectedCategory}
//             onChange={(value) => {
//               setSelectedCategory(value);
//               setSelectedPlatform(devicesData.categories[value][0].Platform);
//             }}
//             rightSection={<IconChevronDown />}
//             allowDeselect={false}
//           />
//           <Select
//             placeholder="Select Platform"
//             data={platforms}
//             value={selectedPlatform}
//             onChange={(value) => setSelectedPlatform(value)}
//             rightSection={<IconChevronDown />}
//             allowDeselect={false}
//           />
//         </Flex>
//         <Select
//           placeholder="Search Device"
//           searchable
//           rightSection={<IconSearch />}
//         />
//       </Flex>
//       <Paper
//         bg="#FFFFFF"
//         mt="lg"
//         mr="sm"
//         ml="sm"
//         p="lg"
//         style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
//       >
//         {loading ? (
//           <Flex
//             justify="center"
//             align="center"
//             style={{ height: "calc(100vh - 150px)" }}
//           >
//             <Loader size={50} color="#182452" />
//           </Flex>
//         ) : (
//           <ScrollArea style={{ height: "calc(100vh - 150px)" }} pr="md">
//             {selectedCategory &&
//               selectedPlatform &&
//               devicesData.categories[selectedCategory]
//                 .find((category) => category.Platform === selectedPlatform)
//                 .Devices.map((device, index) => (
//                   <Box
//                     key={index}
//                     style={{
//                       border: "1px solid #e0e0e0",
//                       borderRadius: "4px",
//                       cursor: "pointer",
//                       boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//                     }}
//                     m="10px"
//                     p="10px"
//                     mr="sm"
//                     ml="sm"
//                     className="DeviceList"
//                     onClick={() =>
//                       handleDeviceSelect(device.DeviceName, selectedPlatform)
//                     }
//                   >
//                     <Flex justify="space-between">
//                       <Text w={"25%"} c="#393939BA" fw={"bold"}>
//                         {device.DeviceName}
//                       </Text>
//                       <Text w={"25%"} c="#393939BA" fw={"bold"}>
//                         Total Devices: {device.DeviceList.length}
//                       </Text>
//                       <Text w={"25%"} c="#393939BA" fw={"bold"}>
//                         Available Devices:{" "}
//                         {
//                           device.DeviceList.filter(
//                             (dev) => dev.status === "available"
//                           ).length
//                         }
//                       </Text>
//                     </Flex>
//                   </Box>
//                 ))}
//           </ScrollArea>
//         )}
//       </Paper>
//     </div>
//   );
// }

import React, { useState, useEffect } from "react";
import {
  Text,
  Select,
  Flex,
  Paper,
  Box,
  ScrollArea,
  Loader,
  Input,
} from "@mantine/core";
import { IconChevronDown, IconSearch } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import "./BookDevice.css";
import styles from "./BookDevice.module.css"; // Ensure file extension matches
import igsLogo from "../../asset/igsLogo.png";

export default function BookDevice() {
  const navigate = useNavigate();
  const { user } = useAuth();
  console.log(user);
  const [devicesData, setDevicesData] = useState({ categories: {} });
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedPlatform, setSelectedPlatform] = useState("");
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  console.log(searchQuery);
  useEffect(() => {
    fetch(`${API_BASE_URL}`)
      .then((response) => response.json())
      .then((data) => {
        setDevicesData({ categories: data });
        const initialCategory = Object.keys(data)[0];
        const initialPlatform = data[initialCategory][0].Platform;
        setSelectedCategory(initialCategory);
        setSelectedPlatform(initialPlatform);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching devices data:", error);
        setLoading(false);
      });
  }, [API_BASE_URL]);

  useEffect(() => {
    if (selectedCategory) {
      setSelectedPlatform(devicesData.categories[selectedCategory][0].Platform);
    }
  }, [selectedCategory, devicesData]);

  const handleDeviceSelect = (DeviceName, Platform) => {
    navigate(
      `/book-device/bookDeviceList/${selectedCategory}/${Platform}/${DeviceName}`
    );
  };

  const platforms =
    selectedCategory && devicesData.categories[selectedCategory]
      ? devicesData.categories[selectedCategory].map(
          (category) => category.Platform
        )
      : [];

  const filteredDevices =
    selectedCategory && selectedPlatform
      ? devicesData.categories[selectedCategory]
          .find((category) => category.Platform === selectedPlatform)
          .Devices.filter((device) =>
            searchQuery
              .split(" ")
              .some((query) =>
                [
                  device.DeviceName,
                  device.DeviceList.length,
                  ...device.DeviceList.map((dev) => dev.status),
                ]
                  .join(" ")
                  .toLowerCase()
                  .includes(query.toLowerCase())
              )
          )
      : [];

  return (
    <div>
      <Flex justify="space-between" align="center" px="md">
        <Flex align="center" gap={"xl"}>
          <Text c={"#393939BA"} fw={"bold"} size="20px">
            Book Device
          </Text>
          <Select
            placeholder="Select Category"
            data={Object.keys(devicesData.categories)}
            value={selectedCategory}
            onChange={(value) => {
              setSelectedCategory(value);
              setSelectedPlatform(devicesData.categories[value][0].Platform);
            }}
            rightSection={<IconChevronDown />}
            allowDeselect={false}
          />
          <Select
            placeholder="Select Platform"
            data={platforms}
            value={selectedPlatform}
            onChange={(value) => setSelectedPlatform(value)}
            rightSection={<IconChevronDown />}
            allowDeselect={false}
          />
        </Flex>
        {/* <Input
          placeholder="Search Device"
          value={searchQuery}
          onChange={(event) => setSearchQuery(event.target.value)}
          searchable
          rightSection={<IconSearch />}
        /> */}
        {/* <input
          type="text"
          placeholder="Search Device"
          value={searchQuery}
          onChange={(event) => setSearchQuery(event.target.value)}
          style={{
            border: "1px solid #ccc",
            padding: "8px",
            borderRadius: "4px",
            width: "200px",
          }}
        /> */}
         
         <Flex align="center" gap="sm">
          <Input
            placeholder="Search Device"
            value={searchQuery}
            onChange={(event) => setSearchQuery(event.target.value)}
            searchable
            rightSection={<IconSearch />}
          />
          <div className={styles.igsLogo}>
            <img src={igsLogo} alt="igsLogo" />
          </div>
        </Flex>
      </Flex>
      <Paper
        bg="#FFFFFF"
        mt="lg"
        mr="sm"
        ml="sm"
        p="lg"
        style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
      >
        {loading ? (
          <Flex
            justify="center"
            align="center"
            style={{ height: "calc(100vh - 150px)" }}
          >
            <Loader size={50} color="#182452" />
          </Flex>
        ) : (
          <ScrollArea style={{ height: "calc(100vh - 150px)" }} pr="md">
            {filteredDevices.map((device, index) => (
              <Box
                key={index}
                style={{
                  border: "1px solid #e0e0e0",
                  borderRadius: "4px",
                  cursor: "pointer",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                m="10px"
                p="10px"
                mr="sm"
                ml="sm"
                className="DeviceList"
                onClick={() =>
                  handleDeviceSelect(device.DeviceName, selectedPlatform)
                }
              >
                <Flex justify="space-between">
                  <Text w={"25%"} c="#393939BA" fw={"bold"}>
                    {device.DeviceName}
                  </Text>
                  <Text w={"25%"} c="#393939BA" fw={"bold"}>
                    Total Devices: {device.DeviceList.length}
                  </Text>
                  <Text w={"25%"} c="#393939BA" fw={"bold"}>
                    Available Devices:{" "}
                    {
                      device.DeviceList.filter(
                        (dev) => dev.status === "available"
                      ).length
                    }
                  </Text>
                </Flex>
              </Box>
            ))}
          </ScrollArea>
        )}
      </Paper>
    </div>
  );
}
