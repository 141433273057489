

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Text, Flex, Paper, Box, Divider, Button } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import igsLogo from "../../asset/igsLogo.png";

export default function MyDevice() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [devices, setDevices] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [deviceUsageHistory, setDeviceUsageHistory] = useState([]);
  const [loading, setLoading] = useState(true); // State to manage loading state
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchDevices = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/devices/get/user/${user.Data.Email}`
        );

        const formattedHistory = response.data.deviceUsageHistory
          .filter((historyItem) => historyItem.dateOfReturn !== null)
          .slice()
          .reverse()
          .map((historyItem) => ({
            ...historyItem,
            dateofbooking: new Date(
              historyItem.dateofbooking
            ).toLocaleDateString("en-IN", {
              timeZone: "Asia/Kolkata",
            }),
            dateOfReturn: historyItem.dateOfReturn
              ? new Date(historyItem.dateOfReturn).toLocaleDateString("en-IN", {
                  timeZone: "Asia/Kolkata",
                })
              : "Not returned",
          }));

        setDeviceUsageHistory(formattedHistory);

        const bookedDevices = response.data.bookedDevices.map((device) => {
          const lastBooking = device.bookHistory[device.bookHistory.length - 1];
          return {
            category: device.categoryName,
            name: device.deviceName,
            did: device.did,
            platform: device.platformName || "Unknown Platform",
            status: lastBooking.dateOfReturn ? "returned" : "notReturned",
            bookingDate: lastBooking.dateofbooking
              ? new Date(lastBooking.dateofbooking).toLocaleDateString(
                  "en-IN",
                  {
                    timeZone: "Asia/Kolkata",
                  }
                )
              : "N/A",
            returnDate: lastBooking.dateOfReturn
              ? new Date(lastBooking.dateOfReturn).toLocaleDateString("en-IN", {
                  timeZone: "Asia/Kolkata",
                })
              : "Not returned",
          };
        });

        setDevices(bookedDevices);
        setLoading(false); // Set loading to false after fetching data
      } catch (error) {
        console.error("Error fetching devices:", error);
        setLoading(false); // Handle loading state in case of error
      }
    };

    fetchDevices();
  }, [API_BASE_URL, user.Data.Email]);

  const filteredDevices = devices.filter((device) =>
    device.name.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <div>
      {/* <Text px="md" c={"#393939BA"} fw={"bold"} size="xl">
        My Devices
      </Text> */}
        <Flex justify="space-between" align="center" px="md" mt="md">
        <Text c="#393939BA" fw="bold" size="xl">
          My Devices
        </Text>
        <img
          src={igsLogo}
          alt="IGS Logo"
          style={{ width: "40px", height: "40px", marginRight: "10px" }}
        />
      </Flex>
      <Flex direction="row" gap="md" mt="lg" m="md">
        <Paper shadow="xs" p="md" w="49%" h="70vh">
          <Paper bg="#00000008" shadow="xs" withBorder>
            <Text p="md" c={"#393939BA"} fw={"bold"} size="20px" align="center">
              Assigned Devices
            </Text>
          </Paper>
          <Box
            p="10px"
            mt="md"
            bg="#182452"
            style={{
              border: "1px solid #e0e0e0",
              borderRadius: "4px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Flex>
              <Text w={"35%"} fw={"bold"} c="#FFFFFF">
                Platform
              </Text>
              <Text w={"35%"} fw={"bold"} c="#FFFFFF">
                Device Name
              </Text>
              <Text w={"30%"} fw={"bold"} c="#FFFFFF">
                Date of Booking
              </Text>
            </Flex>
          </Box>
          {loading && <Text>Loading...</Text>}
          {!loading && filteredDevices.length === 0 && (
            <Text
              p="md"
              mt="lg"
              align="center"
              c={"#393939BA"}
              fw={"bold"}
              size="20px"
            >
              No assigned devices.
            </Text>
          )}
          {!loading &&
            filteredDevices.slice(0, 3).map((device, index) => (
              <Box key={index} mb="md" mt="lg">
                <Paper px="sm" py="sm" shadow="sm" withBorder>
                  <Flex justify="space-between">
                    <Text w="35%" c="#393939BA" fw="600">
                      {device.platform}
                    </Text>
                    <Text w="35%" c="#393939BA" fw="600">
                      {device.name}
                    </Text>
                    <Text w="30%" c="#393939BA" fw="600">
                      {device.bookingDate}
                    </Text>
                  </Flex>
                </Paper>
              </Box>
            ))}
          {!loading && filteredDevices.length !== 0 && (
            <Flex direction="row" justify="end" align="end">
              <Button
                bg="#182452"
                onClick={() => navigate("/return-device/AssignedDevice")}
              >
                View All
              </Button>
            </Flex>
          )}
        </Paper>

        <Divider size="2.5px" orientation="vertical" my="md" />

        <Paper shadow="xs" p="md" w="49%" h="70vh">
          <Paper bg="#00000008" shadow="xs" withBorder>
            <Text p="md" c={"#393939BA"} fw={"bold"} size="20px" align="center">
              Device History
            </Text>
          </Paper>
          <Box
            p="10px"
            mt="md"
            bg="#182452"
            style={{
              border: "1px solid #e0e0e0",
              borderRadius: "4px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Flex>
              <Text w={"35%"} fw={"bold"} c="#FFFFFF">
                Device Name
              </Text>
              <Text w={"35%"} fw={"bold"} c="#FFFFFF">
                Date of Booking
              </Text>
              <Text w={"30%"} fw={"bold"} c="#FFFFFF">
                Date of Return
              </Text>
            </Flex>
          </Box>

          {deviceUsageHistory.slice(0, 3).map((historyItem, index) => (
            <Box key={index} mt="lg">
              <Paper px="sm" py="sm" shadow="sm" withBorder>
                <Flex justify="space-between">
                  <Text w="35%" c="#393939BA" fw="600">
                    {historyItem.deviceName}
                  </Text>
                  <Text w="35%" c="#393939BA" fw="600">
                    {historyItem.dateofbooking}
                  </Text>
                  <Text w="30%" c="#393939BA" fw="600">
                    {historyItem.dateOfReturn || "------"}
                  </Text>
                </Flex>
              </Paper>
            </Box>
          ))}
          <Flex direction="row" justify="end" align="end" mt="lg">
            <Button
              bg="#182452"
              onClick={() => navigate("/return-device/DeviceHistroy")}
            >
              View All
            </Button>
          </Flex>
        </Paper>
      </Flex>
    </div>
  );
}
