import React, { useState, useEffect } from "react";
import {
  Box,
  Divider,
  Flex,
  Paper,
  Text,
  Modal,
  Input,
  Button,
  Select,
  Loader,
  ScrollArea,
} from "@mantine/core";
import {
  IconEdit,
  IconPlus,
  IconTrash,
  IconChevronDown,
} from "@tabler/icons-react";
import axios from "axios";
import { useDisclosure } from "@mantine/hooks";
import { useNavigate } from "react-router-dom";
import AssetOwner from "./DeviceMaster/AssetOwner";
import ProjectMaster from "./DeviceMaster/ProjectMaster";

export default function AddCategories() {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [platforms, setPlatforms] = useState([]);
  const [devices, setDevices] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedPlatform, setSelectedPlatform] = useState("");
  const [newPlatformName, setNewPlatformName] = useState("");
  const [deviceToEdit, setDeviceToEdit] = useState(""); // State for device name edit
  const [newDeviceName, setNewDeviceName] = useState("");
  const [opened, { open, close }] = useDisclosure(false);
  const [platformToDelete, setPlatformToDelete] = useState(""); // State for platform deletion
  const [
    deletePlatformModalOpened,
    { open: openDeletePlatformModal, close: closeDeletePlatformModal },
  ] = useDisclosure(false);

  const [editModalOpened, { open: openEditModal, close: closeEditModal }] =
    useDisclosure(false);
  const [
    deleteDeviceNameModalOpened,
    { open: openDeleteDeviceNameModal, close: closeDeleteDeviceNameModal },
  ] = useDisclosure(false);
  const [
    addPlatfomModalOpened,
    { open: openaddPlatfomModal, close: closeaddPlatfomModal },
  ] = useDisclosure(false);
  const [
    editPlatfomModalOpened,
    { open: openEditPlatfomModal, close: closeEditPlatfomModal },
  ] = useDisclosure(false);
  const [
    editDeviceNameModalOpened,
    { open: openEditDeviceNameModal, close: closeEditDeviceNameModal },
  ] = useDisclosure(false);
  const [
    addDeviceModalOpened,
    { open: openAddDeviceModal, close: closeAddDeviceModal },
  ] = useDisclosure(false);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [editCategoryName, setEditCategoryName] = useState("");
  const [categoryToEdit, setCategoryToEdit] = useState("");
  const [editPlatformName, setEditPlatformName] = useState("");
  const [platformToEdit, setPlatformToEdit] = useState("");
  const [categoryToDelete, setCategoryToDelete] = useState("");
  const [deviceToDelete, setDeviceToDelete] = useState("");
  const [
    deleteModalOpened,
    { open: openDeleteModal, close: closeDeleteModal },
  ] = useDisclosure(false);

  const fetchCategories = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/category/get`);
      const data = await response.json();
      setCategories(data);
      setLoading(false);

      // Set default selected category to the first one in the response
      if (data.length > 0) {
        setSelectedCategory(data[0].categoryName);
        fetchPlatforms(data[0].categoryName); // Fetch platforms for the default category
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Error fetching data");
      setLoading(false);
    }
  };

  const fetchPlatforms = async (categoryName) => {
    try {
      const response = await fetch(`${API_BASE_URL}/platform/${categoryName}`);
      const data = await response.json();
      setPlatforms(data); // Set platforms to the fetched data array
      if (data.length > 0) {
        // Optionally select the first platform
        setSelectedPlatform(data[0].platformName);
        fetchDevices(categoryName, data[0].platformName); // Fetch devices for the default platform
      } else {
        setDevices([]); // Clear devices if no platforms are found
      }
    } catch (error) {
      console.error("Error fetching platforms:", error);
      setError("Error fetching platforms");
    }
  };

  const fetchDevices = async (categoryName, platformName) => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/devicename/get/cat_pat?categoryName=${categoryName}&platformName=${platformName}`
      );
      if (response.data.error === "Device not found") {
        setDevices([]);
      } else {
        setDevices(response.data);
      }
    } catch (error) {
      console.error("Error fetching devices:", error);
      // setError("Error fetching devices");
      setDevices([]);
    }
  };
  useEffect(() => {
    fetchCategories();
  }, []);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    fetchPlatforms(category);
  };
  const handlePlatformChange = (platform) => {
    setSelectedPlatform(platform);
    fetchDevices(selectedCategory, platform);
  };

  const handleDeletePlatform = async () => {
    try {
      const response = await axios.delete(`${API_BASE_URL}/platform/delete`, {
        data: {
          categoryName: selectedCategory,
          platformName: platformToDelete,
        },
      });
      console.log("Platform deleted successfully:", response.data);
      fetchPlatforms(selectedCategory); // Refresh platforms list
      closeDeletePlatformModal();
    } catch (error) {
      console.error("Failed to delete platform:", error);
      setError("Failed to delete platform");
    }
  };
  const handleAddCategory = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/category/add`, {
        categoryName: newCategoryName,
        added_on: new Date().toLocaleDateString(),
        added_by: "Moni",
      });
      console.log("Category added successfully:", response.data);
      fetchCategories(); // Fetch categories again to update the list
      close();
      setNewCategoryName("");
    } catch (error) {
      console.error("Failed to add category:", error);
      setError("Failed to add category");
    }
  };
  const handleAddDeviceName = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/devicename/add`, {
        categoryName: selectedCategory,
        platformName: selectedPlatform,
        deviceName: newDeviceName,
      });

      fetchDevices(selectedCategory, selectedPlatform); // Refresh devices list
      closeAddDeviceModal();
    } catch (error) {
      console.error("Failed to add device name:", error);
      setError("Failed to add device name");
    }
  };
  const handleAddPlatform = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/platform/add`, {
        categoryName: selectedCategory,
        added_on: new Date().toLocaleDateString(),
        added_by: "Moni",
        platformName: newPlatformName,
      });
      fetchPlatforms(selectedCategory); // Refresh platforms list
      closeaddPlatfomModal();
      setNewPlatformName("");
    } catch (error) {
      console.error("Failed to add platform:", error);
      setError("Failed to add platform");
    }
  };

  const handleEditCategory = async () => {
    try {
      const response = await axios.put(`${API_BASE_URL}/category/edit`, {
        oldCategoryName: categoryToEdit,
        newCategoryName: editCategoryName,
      });
      console.log("Category edited successfully:", response.data);
      fetchCategories(); // Fetch categories again to update the list
      closeEditModal();
    } catch (error) {
      console.error("Failed to edit category:", error);
      setError("Failed to edit category");
    }
  };

  const handleDeleteCategory = async () => {
    try {
      const response = await axios.delete(`${API_BASE_URL}/category/delete`, {
        data: {
          categoryName: categoryToDelete,
        },
      });
      console.log("Category deleted successfully:", response.data);
      fetchCategories(); // Fetch categories again to update the list
      closeDeleteModal();
    } catch (error) {
      console.error("Failed to delete category:", error);
      setError("Failed to delete category");
    }
  };

  const handleEditPlatform = async () => {
    try {
      const response = await axios.put(`${API_BASE_URL}/platform/edit`, {
        oldPlatformName: platformToEdit,
        newPlatformName: editPlatformName,
        categoryName: selectedCategory,
      });
      console.log("Platform edited successfully:", response.data);
      fetchPlatforms(selectedCategory); // Fetch platforms again to update the list
      closeEditPlatfomModal();
    } catch (error) {
      console.error("Failed to edit platform:", error);
      setError("Failed to edit platform");
    }
  };
  const handleEditDeviceName = async () => {
    try {
      const response = await axios.put(`${API_BASE_URL}/devicename/update`, {
        categoryName: selectedCategory,
        platformName: selectedPlatform,
        deviceName: deviceToEdit,
        newDeviceName: newDeviceName,
      });
      console.log("Device name edited successfully:", response.data);
      fetchDevices(selectedCategory, selectedPlatform); // Refresh devices list
      closeEditDeviceNameModal();
    } catch (error) {
      console.error("Failed to edit device name:", error);
      setError("Failed to edit device name");
    }
  };

  const handleDeleteDeviceName = async () => {
    try {
      const response = await axios.delete(`${API_BASE_URL}/devicename/delete`, {
        data: {
          categoryName: selectedCategory,
          platformName: selectedPlatform,
          deviceName: deviceToDelete,
        },
      });
      console.log("Device name deleted successfully:", response.data);
      fetchDevices(selectedCategory, selectedPlatform); // Refresh devices list
      closeDeleteDeviceNameModal();
    } catch (error) {
      console.error("Failed to delete device name:", error);
      setError("Failed to delete device name");
    }
  };
  const openDeleteDeviceName = (deviceName) => {
    setDeviceToDelete(deviceName); // Set deviceToDelete state with the device name to delete
    openDeleteDeviceNameModal(); // Open the modal
  };

  const openEditDeviceName = (deviceName) => {
    setDeviceToEdit(deviceName);
    setNewDeviceName(deviceName); // Initialize input with current device name
    openEditDeviceNameModal();
  };

  const openEditCategoryModal = (categoryName) => {
    setCategoryToEdit(categoryName);
    setEditCategoryName(categoryName);
    openEditModal();
  };

  const openDeleteCategoryModal = (categoryName) => {
    setCategoryToDelete(categoryName);
    openDeleteModal();
  };

  const openEditPlatformModal = (platformName) => {
    setPlatformToEdit(platformName);
    setEditPlatformName(platformName);
    openEditPlatfomModal();
  };

  if (loading) {
    return (
      <Flex justify="center" align="center" style={{ height: "100vh" }}>
        <Loader size={50} color="#182452" />
      </Flex>
    );
  }

  if (error) {
    return <Text>{error}</Text>;
  }

  return (
    <div>
      <Flex justify="space-between" align="center" mr="50px" ml="md">
        <Text c={"#393939BA"} fw={"bold"} size="20px">
          Add / Edit Categories
        </Text>
      </Flex>
      <ScrollArea h={"calc(100vh - 65px)"} type="never" pt="md">
        <Paper
          bg="#FFFFFF"
          mt="lg"
          mr="sm"
          ml="sm"
          p="lg"
          x
          style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
        >
          <Button bg="#182452" onClick={() => navigate(-1)} mb="md">
            Back
          </Button>
          <Divider mx="md" />
          <Flex>
            <Box w="30%" mt="69px" mx="lg">
              <Box
                bg="#00000008"
                style={{
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  borderRadius: 5,
                }}
              >
                <Flex justify="space-between" p="lg" align="center">
                  <Text c="#393939BA" fw="bold" size="18px">
                    Categories
                  </Text>
                  <IconPlus
                    style={{
                      cursor: "pointer",
                      width: 30,
                      height: 30,
                    }}
                    onClick={open}
                    color="#393939BA"
                  />
                </Flex>
                <Paper bg="#FFFFF" p="lg">
                  {categories.map((category) => (
                    <Flex
                      key={category._id}
                      align="center"
                      mb="sm"
                      justify="space-between"
                    >
                      <Text c="#393939BA" fw="bold">
                        {category.categoryName}
                      </Text>
                      <Flex gap="lg">
                        <IconEdit
                          color="#393939BA"
                          onClick={() =>
                            openEditCategoryModal(category.categoryName)
                          }
                          style={{ cursor: "pointer", marginLeft: "auto" }}
                        />
                        <IconTrash
                          onClick={() =>
                            openDeleteCategoryModal(category.categoryName)
                          }
                          color="#393939BA"
                          style={{ cursor: "pointer" }}
                        />
                      </Flex>
                    </Flex>
                  ))}
                </Paper>
              </Box>
            </Box>
            <Divider orientation="vertical" />
            <Box w="70%" mt="md">
              <Flex justify="space-around" w="100%" px="lg" gap="md">
                <Select
                  placeholder="Select Category"
                  data={categories.map((category) => category.categoryName)}
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                  rightSection={<IconChevronDown />}
                  allowDeselect={false}
                />
                <Select
                  placeholder="Choose a platform"
                  data={platforms.map((platform) => ({
                    value: platform.platformName,
                  }))}
                  value={selectedPlatform}
                  onChange={handlePlatformChange}
                  rightSection={<IconChevronDown />}
                  allowDeselect={false}
                />
              </Flex>
              <Flex>
                <Box
                  w="50%"
                  mt="md"
                  mx="lg"
                  bg="#00000008"
                  style={{
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    borderRadius: 5,
                  }}
                >
                  <Flex justify="space-between" p="lg" align="center">
                    <Text c="#393939BA" fw="bold" size="18px">
                      Platforms
                    </Text>
                    <IconPlus
                      style={{
                        cursor: "pointer",
                        width: 30,
                        height: 30,
                      }}
                      color="#393939BA"
                      onClick={openaddPlatfomModal}
                    />
                  </Flex>
                  <Paper bg="#FFFFFF" p="lg">
                    {!loading &&
                      platforms.map((platform) => (
                        <Flex
                          key={platform._id}
                          align="center"
                          mb="sm"
                          justify="space-between"
                        >
                          <Text c="#393939BA" fw="bold">
                            {platform.platformName}
                          </Text>
                          <Flex gap="lg">
                            <IconEdit
                              color="#393939BA"
                              style={{ cursor: "pointer", marginLeft: "auto" }}
                              onClick={() =>
                                openEditPlatformModal(platform.platformName)
                              }
                            />
                            <IconTrash
                              color="#393939BA"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setPlatformToDelete(platform.platformName); // Set platform to delete
                                openDeletePlatformModal(); // Open delete confirmation modal
                              }}
                            />
                          </Flex>
                        </Flex>
                      ))}
                  </Paper>
                </Box>
                <Divider orientation="vertical" />
                <Box w="50%">
                  <Box
                    mt="md"
                    mx="lg"
                    bg="#00000008"
                    style={{
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      borderRadius: 5,
                    }}
                  >
                    <Flex justify="space-between" p="lg" align="center">
                      <Text c="#393939BA" fw="bold" size="18px">
                        Device Name
                      </Text>
                      <IconPlus
                        style={{
                          cursor: "pointer",
                          width: 30,
                          height: 30,
                        }}
                        color="#393939BA"
                        onClick={openAddDeviceModal} // This opens the modal to add a new device name
                      />
                    </Flex>
                    <Paper bg="#FFFFF" p="lg">
                      {devices.length > 0 ? (
                        devices.map((device) => (
                          <Flex
                            key={device.deviceName}
                            justify="space-between"
                            mb="sm"
                          >
                            <Text>{device.deviceName}</Text>
                            <Flex gap="lg">
                              <IconEdit
                                color="#393939BA"
                                style={{
                                  cursor: "pointer",
                                  marginLeft: "auto",
                                }}
                                onClick={() =>
                                  openEditDeviceName(device.deviceName)
                                }
                              />
                              <IconTrash
                                color="#393939BA"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  openDeleteDeviceName(device.deviceName)
                                }
                              />
                            </Flex>
                          </Flex>
                        ))
                      ) : (
                        <Text>No devices found for this platform.</Text>
                      )}
                    </Paper>
                  </Box>
                </Box>
              </Flex>
            </Box>
          </Flex>
          <Divider mt="lg" size="3px" mx="lg" />
          <Flex>
            <Box w="50%">
              <Box
                mt="md"
                mx="lg"
                bg="#00000008"
                style={{
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  borderRadius: 5,
                }}
              >
                <AssetOwner />
              </Box>
            </Box>
            <Divider orientation="vertical" mt="md" />
            <Box w="50%">
              <Box
                mt="md"
                mx="lg"
                bg="#00000008"
                style={{
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  borderRadius: 5,
                }}
              >
                <ProjectMaster />
              </Box>
            </Box>
          </Flex>
        </Paper>
        <Modal opened={opened} onClose={close} centered withCloseButton>
          <Text mb="sm" c="#393939BA" fw="bold">
            Add New Category
          </Text>
          <Input
            value={newCategoryName}
            onChange={(e) => setNewCategoryName(e.target.value)}
          />
          <Flex w="100%" justify="flex-end" mt="lg">
            <Button bg="#182452" onClick={handleAddCategory}>
              Add
            </Button>
          </Flex>
        </Modal>
        <Modal
          opened={editModalOpened}
          onClose={closeEditModal}
          centered
          withCloseButton
        >
          <Text mb="sm" c="#393939BA" fw="bold">
            Edit Category
          </Text>
          <Input
            value={editCategoryName}
            onChange={(e) => setEditCategoryName(e.target.value)}
          />
          <Flex w="100%" justify="flex-end" mt="lg">
            <Button bg="#182452" onClick={handleEditCategory}>
              Edit
            </Button>
          </Flex>
        </Modal>
        <Modal
          opened={deleteModalOpened}
          onClose={closeDeleteModal}
          centered
          withCloseButton
        >
          <Text mb="sm" c="#393939BA" fw="bold">
            Confirm Delete Category
          </Text>
          <Text mb="sm">
            Are you sure you want to delete the category "{categoryToDelete}"?
          </Text>
          <Flex w="100%" justify="flex-end" mt="lg">
            <Button bg="#182452" onClick={handleDeleteCategory}>
              Delete
            </Button>
          </Flex>
        </Modal>
        <Modal
          opened={addPlatfomModalOpened}
          onClose={closeaddPlatfomModal}
          centered
          withCloseButton
        >
          <Text mb="sm" c="#393939BA" fw="bold">
            Add New Platform
          </Text>
          <Input
            placeholder="Enter Platform Name"
            value={newPlatformName}
            onChange={(e) => setNewPlatformName(e.target.value)}
          />
          <Flex w="100%" justify="flex-end" mt="lg">
            <Button bg="#182452" onClick={handleAddPlatform}>
              Add
            </Button>
          </Flex>
        </Modal>
        <Modal
          opened={editPlatfomModalOpened}
          onClose={closeEditPlatfomModal}
          centered
          withCloseButton
        >
          <Text mb="sm" c="#393939BA" fw="bold">
            Edit Platform
          </Text>
          <Input
            value={editPlatformName}
            onChange={(e) => setEditPlatformName(e.target.value)}
          />
          <Flex w="100%" justify="flex-end" mt="lg">
            <Button bg="#182452" onClick={handleEditPlatform}>
              Save
            </Button>
          </Flex>
        </Modal>
        <Modal
          opened={editDeviceNameModalOpened}
          onClose={closeEditDeviceNameModal}
          centered
          withCloseButton
        >
          <Text mb="sm" c="#393939BA" fw="bold">
            Edit Device Name
          </Text>
          <Input
            placeholder="Enter New Device Name"
            value={newDeviceName} // Use newDeviceName to edit the device name
            onChange={(e) => setNewDeviceName(e.target.value)}
          />
          <Flex w="100%" justify="flex-end" mt="lg">
            <Button bg="#182452" onClick={handleEditDeviceName}>
              Save
            </Button>
          </Flex>
        </Modal>
        <Modal
          opened={deleteDeviceNameModalOpened}
          onClose={closeDeleteDeviceNameModal}
          centered
          withCloseButton
        >
          <Text mb="sm"  c="#393939BA" fw="bold">
            Are you sure you want to delete the device name "{deviceToDelete}"?
          </Text>
          <Flex w="100%" justify="flex-end" mt="lg">
            <Button bg="#182452" onClick={handleDeleteDeviceName}>
              Delete
            </Button>
          </Flex>
        </Modal>
        <Modal
          opened={addDeviceModalOpened}
          onClose={closeAddDeviceModal}
          centered
          withCloseButton
        >
          <Text mb="sm" c="#393939BA" fw="bold">
            Add New Device Name
          </Text>
          <Input
            placeholder="Enter Device Name"
            value={newDeviceName}
            onChange={(e) => setNewDeviceName(e.target.value)}
          />
          <Flex w="100%" justify="flex-end" mt="lg">
            <Button bg="#182452" onClick={handleAddDeviceName}>
              Add
            </Button>
          </Flex>
        </Modal>
        <Modal
          opened={deletePlatformModalOpened}
          onClose={closeDeletePlatformModal}
          centered
          withCloseButton
        >
          <Text mb="sm" c="#393939BA" fw="bold">
            Confirm Delete Platform
          </Text>
          <Text mb="sm">
            Are you sure you want to delete the platform "{platformToDelete}"?
          </Text>
          <Flex w="100%" justify="flex-end" mt="lg">
            <Button bg="#182452" onClick={handleDeletePlatform}>
              Delete
            </Button>
          </Flex>
        </Modal>
      </ScrollArea>
    </div>
  );
}
