// import {
//   Box,
//   Button,
//   Card,
//   Flex,
//   Group,
//   Paper,
//   ScrollArea,
//   Text,
// } from "@mantine/core";
// import React, { useState, useEffect } from "react";
// import Pie from "../../components/pagesComponent/DashboardComponents/Pie";
// import AvailableBarChart from "../../components/pagesComponent/DashboardComponents/AvailableBarChart";
// import axios from "axios";
// import ProjectTable from "../../components/pagesComponent/DashboardComponents/ProjectTable";
// import CalculateTotalCost from "../../components/pagesComponent/DashboardComponents/CalculateTotalCost";
// import { useNavigate } from "react-router-dom";
// import { IconAlertCircle, IconDeviceImacX } from "@tabler/icons-react";
// // import CalculateTotalCost from "../../components/pagesComponent/DashboardComponents/TotalCostChart";

// export default function Dashboard() {
//   const [data, setData] = useState([]);
//   const [inputData, setInputData] = useState([]);
//   const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
//   const [data2, setData2] = useState([]);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const delayFetch = setTimeout(() => {
//       axios
//         .get(
//           "https://d13d04nxcwaid6.cloudfront.net/api/v1/admin/devices/countbyprojectandcategory"
//         )
//         .then((response) => {
//           setData2(Object.entries(response.data));
//         })
//         .catch((error) => {
//           console.error("There was an error fetching the data!", error);
//         });
//     }, 1000); // Delay of 2 seconds

//     return () => clearTimeout(delayFetch); // Cleanup in case the component unmounts
//   }, []);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(
//           `${API_BASE_URL}/devices/asset-owner-by-category`
//         );
//         const transformedData = response.data.map((categoryObj) => {
//           const { categoryName, assetOwners } = categoryObj;
//           const devices = assetOwners.reduce((acc, owner) => {
//             acc[owner.ownerName] = owner.deviceCount;
//             return acc;
//           }, {});
//           return { [categoryName]: devices };
//         });
//         setData(transformedData);
//       } catch (error) {
//         console.error("Error fetching data: ", error);
//       }
//     };

//     fetchData();
//   }, []);

//   const fetchAndProcessData = async () => {
//     try {
//       const response = await axios.get(`${API_BASE_URL}`);
//       const data = response.data;
//       console.log("API Response:", data); // Add this line to inspect the API response

//       const processedData = Object.keys(data).map((categoryName) => {
//         const platforms = data[categoryName];

//         console.log(`Processing category: ${categoryName}`, platforms); // Add this line to inspect platforms

//         let totalDevices = 0;
//         let availableDevices = 0;

//         platforms.forEach((platform) => {
//           platform.Devices.forEach((device) => {
//             device.DeviceList.forEach((deviceDetail) => {
//               totalDevices++;
//               if (deviceDetail.status === "available") {
//                 availableDevices++;
//               }
//             });
//           });
//         });

//         return {
//           [categoryName]: {
//             "Total Devices": totalDevices.toString(),
//             "Available Devices": availableDevices.toString(),
//           },
//         };
//       });

//       setInputData(processedData);
//     } catch (error) {
//       console.error("Error fetching data: ", error);
//     }
//   };

//   useEffect(() => {
//     const delayFetch = setTimeout(() => {
//       fetchAndProcessData();
//     }, 300);

//     return () => clearTimeout(delayFetch);
//   }, []);

//   return (
//     <Paper shadow="sm" bg="#FFFFFF80">
//       <Text c={"#393939BA"} fw={"bold"} size="20px" px="md" pt="md">
//         Dashboard
//       </Text>
//       <ScrollArea h={"calc(100vh - 90px)"} type="never">
//         <Paper m="md" shadow="sm" withBorder bg="#EBEBEB">
//           <Text p="sm" c={"#393939BA"} fw={"bold"} size="20px">
//             Total Devices
//           </Text>
//           <ScrollArea
//             w={"calc(100vw - 360px)"}
//             scrollbars="x"
//             bg="#FFFFFF"
//             m="md"
//             style={{ borderRadius: "5px" }}
//           >
//             <Flex gap="md" m="md" justify="space-evenly">
//               {data.map((categoryObj, index) => {
//                 const categoryName = Object.keys(categoryObj)[0];
//                 const devices = categoryObj[categoryName];
//                 const series = Object.values(devices).map(Number);
//                 const labels = Object.keys(devices);

//                 return (
//                   <Paper
//                     key={index}
//                     // w="20%"
//                     bg="white"
//                     shadow="sm"
//                     radius="md"
//                     withBorder
//                   >
//                     <Box align="center" bg="#EBEBEB">
//                       <Text c="#393939BA" fw={"bold"} size="20px" p="sm">
//                         {categoryName}
//                       </Text>
//                     </Box>
//                     <Box mt="md" p="xs">
//                       <Pie series={series} labels={labels} />
//                     </Box>
//                   </Paper>
//                 );
//               })}
//             </Flex>
//           </ScrollArea>
//         </Paper>
//         <Paper m="md" shadow="sm" withBorder bg="#EBEBEB">
//           <Text p="sm" c={"#393939BA"} fw={"bold"} size="20px">
//             Project Assigned
//           </Text>
//           <ScrollArea
//             w={"calc(100vw - 360px)"}
//             scrollbars="x"
//             bg="#FFFFFF"
//             m="md"
//             style={{ borderRadius: "5px" }}
//             mih="250px"
//           >
//             <Flex gap="md" m="md" justify="space-evenly">
//               {data2.map(([categoryName, devices], index) => {
//                 const series = Object.values(devices).map(Number);
//                 const labels = Object.keys(devices);

//                 return (
//                   categoryName !== "undefined" && (
//                     <Paper
//                       key={index}
//                       bg="white"
//                       shadow="sm"
//                       radius="md"
//                       withBorder
//                     >
//                       <Box align="center" bg="#EBEBEB">
//                         <Text c="#393939BA" fw={"bold"} size="20px" p="sm">
//                           {categoryName}
//                         </Text>
//                       </Box>
//                       <Box mt="md" p="xs">
//                         <ProjectTable series={series} labels={labels} />
//                       </Box>
//                     </Paper>
//                   )
//                 );
//               })}
//             </Flex>
//           </ScrollArea>
//         </Paper>

//         {/* <Paper bg="#EBEBEB" shadow="sm" radius="sm" withBorder m="md">
//           <Text c="#393939BA" fw={"bold"} size="20px" p="sm">
//             Total Cost
//           </Text>
//           <Paper m="md" bg="#FFFFFF" align="center" pt="80px">
//             <CalculateTotalCost />
//           </Paper>
//         </Paper> */}

//         <Paper bg="#EBEBEB" shadow="sm" radius="sm" withBorder m="md">
//           <Text c="#393939BA" fw={"bold"} size="20px" p="sm">
//             Available Device
//           </Text>
//           <Paper m="md" bg="#FFFFFF">
//             <Box w="98%">
//               <AvailableBarChart data={inputData} />
//             </Box>
//           </Paper>
//         </Paper>

//         <Paper bg="#EBEBEB" shadow="sm" radius="sm" withBorder m="md" w="520px">
//           <Text c="#393939BA" fw={"bold"} size="20px" p="sm">
//             Damaged Devices
//           </Text>
//           <Paper m="md" bg="#FFFFFF" align="center" p="lg">
//             <Box p="sm">
//               <Flex
//                 bg="#182452"
//                 w="50px"
//                 h="50px"
//                 align="center"
//                 justify="center"
//                 p="xs"
//                 style={{ borderRadius: "50px" }}
//               >
//                 <IconDeviceImacX size={34} color="#FFFFFF" />
//               </Flex>
//               <Text c="#393939BA" fw={"bold"} size="22px" mt="md">
//                 Total Damaged Devices: 10
//               </Text>
//             </Box>
//             <Box align="end" mt="md">
//               <Button
//                 onClick={() => navigate("/Dashboard/DamagedDeviceList")}
//                 bg="#182452"
//               >
//                 View
//               </Button>
//             </Box>
//           </Paper>
//         </Paper>
//       </ScrollArea>
//     </Paper>
//   );
// }

import {
  Box,
  Button,
  Card,
  Flex,
  Group,
  Loader,
  Paper,
  ScrollArea,
  Text,
} from "@mantine/core";
import React, { useState, useEffect } from "react";
import Pie from "../../components/pagesComponent/DashboardComponents/Pie";
import AvailableBarChart from "../../components/pagesComponent/DashboardComponents/AvailableBarChart";
import axios from "axios";
import ProjectTable from "../../components/pagesComponent/DashboardComponents/ProjectTable";
import { useNavigate } from "react-router-dom";
import { IconAlertCircle, IconDeviceImacX } from "@tabler/icons-react";
import CalculateTotalCost from "../../components/pagesComponent/DashboardComponents/CalculateTotalCost";
import igsLogo from "../../asset/igsLogo.png";
export default function Dashboard() {
  const [data, setData] = useState([]);
  const [inputData, setInputData] = useState([]);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [data2, setData2] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const navigate = useNavigate();
  const [damagedDeviceCount, setDamagedDeviceCount] = useState(0);

  useEffect(() => {
    const delayFetch = setTimeout(() => {
      axios
        .get(`${API_BASE_URL}/devices/countbyprojectandcategory`)
        .then((response) => {
          setData2(Object.entries(response.data));
          setLoading(false); // Set loading to false when data is fetched
        })
        .catch((error) => {
          console.error("There was an error fetching the data!", error);
          setLoading(false); // Set loading to false in case of error
        });
    }, 1000); // Delay of 1 second

    return () => clearTimeout(delayFetch); // Cleanup in case the component unmounts
  }, []);
  useEffect(() => {
    const fetchDamagedDevicesWithDelay = () => {
      setTimeout(async () => {
        try {
          const response = await axios.get(
            `${API_BASE_URL}/devices/getdamaged`
          );
          const damagedDevices = response.data.filter(
            (device) => device.status === "damaged"
          );
          setDamagedDeviceCount(damagedDevices.length);
        } catch (error) {
          console.error("Error fetching damaged devices: ", error);
        }
      }, 600); // 2-second delay
    };

    fetchDamagedDevicesWithDelay();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/devices/asset-owner-by-category`
        );
        const transformedData = response.data.map((categoryObj) => {
          const { categoryName, assetOwners } = categoryObj;
          const devices = assetOwners.reduce((acc, owner) => {
            acc[owner.ownerName] = owner.deviceCount;
            return acc;
          }, {});
          return { [categoryName]: devices };
        });
        setData(transformedData);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  const fetchAndProcessData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}`);
      const data = response.data;
      console.log("API Response:", data); // Add this line to inspect the API response

      const processedData = Object.keys(data).map((categoryName) => {
        const platforms = data[categoryName];

        let totalDevices = 0;
        let availableDevices = 0;

        platforms.forEach((platform) => {
          platform.Devices.forEach((device) => {
            device.DeviceList.forEach((deviceDetail) => {
              totalDevices++;
              if (deviceDetail.status === "available") {
                availableDevices++;
              }
            });
          });
        });

        return {
          [categoryName]: {
            "Total Devices": totalDevices.toString(),
            "Available Devices": availableDevices.toString(),
          },
        };
      });

      setInputData(processedData);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  useEffect(() => {
    const delayFetch = setTimeout(() => {
      fetchAndProcessData();
    }, 300);

    return () => clearTimeout(delayFetch);
  }, []);

  return (
    <Paper shadow="sm" bg="#FFFFFF80">
      {/* <Text c={"#393939BA"} fw={"bold"} size="20px" px="md" pt="md">
        Dashboard
      </Text>
       */}
      <Flex justify="space-between" align="center" px="md" mt="md">
        <Text c={"#393939BA"} fw={"bold"} size="20px" px="md" pt="md">
          Dashboard
        </Text>
        <img
          src={igsLogo}
          alt="IGS Logo"
          style={{
            width: "40px",
            height: "40px",
            marginRight: "10px",
            marginTop: "10px",
          }}
        />
      </Flex>{" "}
      <ScrollArea h={"calc(100vh - 90px)"}>
        <Paper m="md" shadow="sm" withBorder bg="#EBEBEB">
          <Text p="sm" c={"#393939BA"} fw={"bold"} size="20px">
            Total Devices
          </Text>
          <ScrollArea
            w={"calc(100vw - 360px)"}
            scrollbars="x"
            bg="#FFFFFF"
            m="md"
            style={{ borderRadius: "5px" }}
          >
            <Flex gap="md" m="md" justify="space-evenly">
              {data.map((categoryObj, index) => {
                const categoryName = Object.keys(categoryObj)[0];
                const devices = categoryObj[categoryName];
                const series = Object.values(devices).map(Number);
                const labels = Object.keys(devices);

                return (
                  <Paper
                    key={index}
                    // w="20%"
                    bg="white"
                    shadow="sm"
                    radius="md"
                    withBorder
                  >
                    <Box align="center" bg="#EBEBEB">
                      <Text c="#393939BA" fw={"bold"} size="20px" p="sm">
                        {categoryName}
                      </Text>
                    </Box>
                    <Box mt="md" px="xl" pb="2px">
                      <Pie series={series} labels={labels} />
                    </Box>
                  </Paper>
                );
              })}
            </Flex>
          </ScrollArea>
        </Paper>
        <Paper m="md" shadow="sm" withBorder bg="#EBEBEB">
          <Text p="sm" c={"#393939BA"} fw={"bold"} size="20px">
            Project Assigned
          </Text>
          <ScrollArea
            w={"calc(100vw - 360px)"}
            scrollbars="x"
            bg="#FFFFFF"
            m="md"
            style={{ borderRadius: "5px" }}
            mih="250px"
          >
            <Flex gap="md" m="md" justify="space-evenly">
              {loading ? ( // Conditional rendering based on loading state
                <Loader size="lg" color="#182452" />
              ) : (
                data2.map(([categoryName, devices], index) => {
                  const series = Object.values(devices).map(Number);
                  const labels = Object.keys(devices);

                  return (
                    categoryName !== "undefined" && (
                      <Paper
                        key={index}
                        bg="white"
                        shadow="sm"
                        radius="md"
                        withBorder
                      >
                        <Box align="center" bg="#EBEBEB">
                          <Text c="#393939BA" fw={"bold"} size="20px" p="sm">
                            {categoryName}
                          </Text>
                        </Box>
                        <Box mt="md" px="xl" pb="2px">
                          <ProjectTable series={series} labels={labels} />
                        </Box>
                      </Paper>
                    )
                  );
                })
              )}
            </Flex>
          </ScrollArea>
        </Paper>

        <Paper bg="#EBEBEB" shadow="sm" radius="sm" withBorder m="md">
          <Text c="#393939BA" fw={"bold"} size="20px" p="sm">
            Total Cost
          </Text>
          <Paper m="md" bg="#FFFFFF" align="center" pt="80px">
            <CalculateTotalCost />
          </Paper>
        </Paper>

        <Paper bg="#EBEBEB" shadow="sm" radius="sm" withBorder m="md">
          <Text c="#393939BA" fw={"bold"} size="20px" p="sm">
            Available Device
          </Text>
          <Paper m="md" bg="#FFFFFF">
            <Box w="98%">
              <AvailableBarChart data={inputData} />
            </Box>
          </Paper>
        </Paper>

        <Paper bg="#EBEBEB" shadow="sm" radius="sm" withBorder m="md" w="520px">
          <Text c="#393939BA" fw={"bold"} size="20px" p="sm">
            Damaged Devices
          </Text>
          <Paper m="md" bg="#FFFFFF" align="center" p="lg">
            <Box p="sm">
              <Flex
                bg="#182452"
                w="50px"
                h="50px"
                align="center"
                justify="center"
                p="xs"
                style={{ borderRadius: "50px" }}
              >
                <IconDeviceImacX size={34} color="#FFFFFF" />
              </Flex>
              <Text c="#393939BA" fw={"bold"} size="22px" mt="md">
                Total Damaged Devices: {damagedDeviceCount}
              </Text>
            </Box>
            <Box align="end" mt="md">
              <Button
                onClick={() => navigate("/Dashboard/DamagedDeviceList")}
                bg="#182452"
              >
                View
              </Button>
            </Box>
          </Paper>
        </Paper>
      </ScrollArea>
    </Paper>
  );
}
